import React from 'react'
import { PhoneOutlined, MailOutlined } from '@ant-design/icons';
// import { TwitterCircleFilled, TwitterOutlined, GithubFilled, GithubOutlined, LinkedinFilled, LinkedinOutlined, MailOutlined, PhoneOutlined, RedditCircleFilled, RedditOutlined } from '@ant-design/icons'
import { useContext } from 'react';
import ThemeContext from '../state/ThemeContext';
import { Typography, Button } from 'antd'

const { Title } = Typography
export default function Contact(props) {
    const { theme, isDark } = useContext(ThemeContext);
    const socials = props.socials;
    console.log(socials);
    return <div className="social">
        <Title style={theme.heading} level={3}>CONTACT ME</Title>
        <div className="mobile_email">
            <p><PhoneOutlined /> +254 716-276-879</p>
            <p><MailOutlined />amshelkanyi@gmail.com</p></div>

        <div className="social_icons">

            {
                socials.map((social, index) => {
                    console.log(typeof social);
                    return <Button type="primary" ><a key={index} href={social.link} rel="noopener noreferrer" target="_blank">{social.name.toUpperCase()}</a></Button>
                })
            }
        </div>
        <a href="resume.pdf" target="_blank">
            <div style={theme.download} className="download">{
                isDark ? <box-icon name='link-external' size="lg" color="white"></box-icon>
                    : <box-icon name='link-external' type='solid' size="lg" color="#081229"></box-icon>}
                VIEW RESUME</div>
        </a>

    </div>
}