import React, { useContext, useState, useEffect } from 'react';
import '../node_modules/antd/dist/antd.min.css'
import './scss/global.scss'
import './scss/loader.scss'
import Header from './components/header';
import Hero from './components/hero';
import ThemeContext from './state/ThemeContext';
import 'boxicons';
import Project from './components/projects';
import Loader from './components/loader';
import Contact from './components/contact';
import Footer from './components/footer';
import Copyright from './components/copyright';


function App() {
  const [loaded, setLoaded] = useState(false)
  const [projects, setProjects] = useState([])
  const [userInfo, setUserInfo] = useState({})

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}/project`).then(response => response.json()).then(projects => {
        setProjects(projects)
      }).catch(err => console.log(err))


    fetch(`${process.env.REACT_APP_BACKEND_URL}/user`).then(response => response.json()).then(user => {
        setUserInfo(user[0])
        setLoaded(true)
      }).catch(err => console.log(err))

  }, [])
  const { theme } = useContext(ThemeContext)
  // console.log(theme.app);
  return (
    <div className="App" style={theme.app}>
      {
        loaded ? <>
          <Header info={userInfo.searching} />
          <Hero info={userInfo} />
          <Project projects={projects} />
          <Contact socials={userInfo.socials} />
          <Footer />
          <Copyright />
        </> : <Loader />
      }
    </div>
  );
}

export default App;
